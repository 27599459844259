import type { PaginationResponse } from '@/types'

export const useGetRoles = async (params?: any) => {
  const { data, error } = await useAPIFetch(`/roles`, { params })
  if (error.value) throw createError(error.value)
  return data.value as PaginationResponse
}

export const useSetUsersRoles = async (userIds: number[], roleId: number) => {
  const { data, error } = await useAPIFetch(`/user/roles`, {
    method: 'POST',
    body: { user_ids: userIds, role_id: roleId },
  })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useSetMarketerRole = async (body: object) => {
  const { data, error } = await useAPIFetch(`/users/marketers`, {
    method: 'PATCH',
    body,
  })
  if (error.value) throw createError(error.value)
  return data.value
}
